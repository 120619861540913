@import "./Components/Styles/Variables.module.scss";
@import "./Components/Styles/Mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.progress-container {
  position: fixed;
  top: 35%;
  bottom: 35%;
  right: 20px;
  width: 5px;
  background: #000;
  @include xs {
    right: 7px;
  }
}

.progress {
  position: fixed;
  top: 35%;
  bottom: 35%;
  right: 20px;
  width: 5px;
  background: $logo-orange;
  @include xs {
    right: 7px;
  }
}

::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  background: var(--background);
}

html {
  font-size: 20px;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
