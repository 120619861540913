@import "./Variables.module.scss";
@import "./Mixins.scss";

.header-image-container {
  width: 90%;
  height: fit-content;
  margin-top: 45px;
  height: 320px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  @include xs {
    justify-content: flex-start;
    width: 100%;
  }
  .supporting-text {
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
    font-size: 2vw;
    color: #023047;
    padding: 0px 30px 0px;
    margin: 0px;
    .anchor-text {
      text-decoration: underline;
      &:hover {
        color: black;
        cursor: pointer;
      }
    }
    @include xs {
      padding: 12px 0px 10px;
      font-size: 20px;
    }
  }
  .align-left {
    text-align: right;
    @include xs {
      padding: 12px 0px 10px;
    }
  }
  .character-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: auto;
    p {
      font-size: 1.2vw;
      margin: 0px;
      text-align: center;
      font-weight: 600;
      white-space: nowrap;
      padding: 0px 0px 25px;
      @include xs {
        font-size: 1.2vw;
        padding: 0px 5px 10px;
      }
    }
    .active {
      transform: scale(1.2);
      transition: transform 0.3s ease;
      z-index: 1000;
    }
    img {
      width: 120%;
      height: auto;
      transform: scale(1);
      transition: transform 0.3s;
      &:not(.active):hover {
        z-index: 100;
        cursor: pointer;
        transform: scale(1.05);
        transition: transform 0.2s ease-out;
        -webkit-filter: drop-shadow(1.5px 1.5px 1.5px rgba(155, 155, 155, 0.7))
          drop-shadow(-1.5px 1.5px 1.5px rgba(155, 155, 155, 0.7))
          drop-shadow(1.5px -1.5px 1.5px rgba(155, 155, 155, 0.7))
          drop-shadow(-1.5px -1.5px 1.5px rgba(155, 155, 155, 0.7));

        filter: drop-shadow(1.5px 1.5px 1.5px rgba(155, 155, 155, 0.7))
          drop-shadow(-1.5px 1.5px 1.5px rgba(155, 155, 155, 0.7))
          drop-shadow(1.5px -1.5px 1.5px rgba(155, 155, 155, 0.7))
          drop-shadow(-1.5px -1.5px 1.5px rgba(155, 155, 155, 0.7));
      }
      @include xs {
        width: 100%;
      }
    }
  }

  .header-image {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    @include xs {
      width: 100%;
      font-size: 20px;
    }
  }
}
