$logo-blue: #023047;
$logo-orange: #fb8500;

// Breakpoints
//Extra small devices
$xs-breakpoint: 0px;
// Small tablets and large smartphones (landscape view)
$sm-breakpoint: 576px;

// Small tablets (portrait view)
$md-breakpoint: 768px;

// Tablets and small desktops
$lg-breakpoint: 992px;

// Large tablets and desktops
$xl-breakpoint: 1200px;

:export {
  logo-blue: $logo-blue;
  logo-orange: $logo-orange;
}
