@import "./Variables.module.scss";
@import "./Mixins.scss";

.cost-comparison {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  .background-color {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 252, 227, 1) 0%,
      rgba(128, 150, 149, 1) 40%,
      rgba(61, 95, 107, 1) 75%,
      rgba(35, 75, 92, 1) 95%,
      rgba(2, 48, 71, 1) 100%
    );
    z-index: -2;
  }
  .background-container {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -2;
    @include xs {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 100%;
      z-index: -2;
      overflow: hidden;
      background-color: transparent;
    }
  }
  .mobile-background {
    &-shape {
      position: absolute;
      height: 60%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      opacity: 0;
      @include xs {
        opacity: 1;
      }
    }
  }
  .octopus-bg {
    position: absolute;
    width: 60%;
    bottom: -5%;
    left: -5%;
    height: auto;
    transform: rotate(14deg);
    z-index: -2;
    @include xs {
      width: 122%;
      bottom: 0%;
      left: -33%;
      height: auto;
      transform: rotate(-7deg);
    }
  }
  .rock-bg {
    position: absolute;
    width: 62%;
    bottom: 0%;
    right: 0%;
    height: auto;
    z-index: -2;
    @include xs {
      width: 100%;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 70px;
    height: fit-content;
    flex-direction: row;
    @include xs {
      margin-top: 60px;
    }
  }
  .shapes {
    position: absolute;
    left: 0;
    height: 100%;
    width: 48.6%;
    z-index: -1;
  }
  .section-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    @include xs {
      width: 100%;
    }
    .main-text {
      color: #ffffff;
      font-size: 3vw;
      font-weight: 900;
      width: 85%;
      text-align: center;
      margin: 0;
      text-transform: uppercase;
      @include xs {
        font-size: 3.6vh;
      }
    }
    .sub-text {
      color: $logo-blue;
      font-size: 1.6vw;
      font-weight: 600;
      font-style: italic;
      text-align: center;
      width: 60%;
      margin: 0;
      span {
        font-weight: 900;
      }
      @include xs {
        font-size: 2.6vh;
      }
    }
  }
}
