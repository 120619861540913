@import "./Variables.module.scss";
@import "./Mixins.scss";

.header-text-container {
  display: flex;
  width: 60%;
  flex-direction: column;
  height: fit-content;
  justify-content: start;
  @include xs {
    width: 98%;
  }
}

.header-text {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  width: 100%;
  h1 {
    margin: 0px;
    color: $logo-blue;
    font-weight: 800;
    font-size: 2.6vw;
    text-align: start;
    padding-bottom: 16px;
    margin: 0px;
    padding: 50px 0px 1vw;
    @include xs {
      padding: 0px 0px 15px;
      font-size: 4vh;
    }
  }
  .header-text-orange {
    color: $logo-orange;
  }
  h2 {
    margin: 0px;
    font-size: 1.4vw;
    font-weight: 500;
    padding: 0px 0px 1.5vw;
    @include xs {
      font-size: 2.4vh;
    }
  }
}

.header-button {
  height: fit-content;
  display: flex;
  align-items: start;
  .header-button-btn {
    border: 2px solid $logo-orange;
    height: 100%;
    font-weight: 700;
    white-space: nowrap;
    font-size: 1.5vw;
    padding: 1vw 3vw;
    background: transparent;
    color: $logo-blue;
    &:hover {
      cursor: pointer;
      box-shadow: 3px 4px $logo-blue;
    }
    &:active {
      background-color: #fffce3;
      box-shadow: 3px 4px #666;
      transform: translateY(2px);
    }
    @include xs {
      font-size: 4vw;
      padding: 1vw 4vw;
      white-space: nowrap;
      margin-top: 4vw;
    }
  }
}
