@import "./Variables.module.scss";

#calendly-embed {
  min-width: 345px;
  height: 600px;
  .calendly-badge-widget {
    border: 2px solid $logo-orange;
    right: 5%;
    bottom: 5%;
    &:hover {
      scale: 1.05;
      transition: scale 0.2s;
    }
    &:hover > .calendly-badge-content {
      background-color: $logo-orange;
    }
  }
  .calendly-badge-content {
    padding: 0 15px;
    border-radius: 0px;
    &:hover {
      color: $logo-orange;
    }
  }
}
