@import "./Variables.module.scss";
@import "./Mixins.scss";

.navbar {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: transparent;

  .navbar-container {
    display: flex;
    height: 100%;
    width: 90%;
    justify-content: space-between;
    align-items: center;
  }

  .navitem-container {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 24px 0px;
  }

  button {
    background-color: transparent;
    border: 0px;
    font-size: 18px;
    color: $logo-blue;
    height: 100%;
    border-bottom: 2px solid $logo-blue;
    padding: 0px;
    font-style: italic;
  }

  button:visited {
    color: $logo-blue;
    text-decoration: none;
  }

  button:hover {
    color: $logo-orange;
    cursor: pointer;
    border-bottom: 2px solid $logo-orange;
  }

  img {
    height: 6vw;
    @include xs {
      height: 60px;
    }
  }
}
