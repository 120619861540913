@import "./Variables.module.scss";
@import "./Mixins.scss";

.header {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;

  .header-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    @include xs {
      justify-content: flex-start;
      align-items: flex-start;
      height: 70vh;
      flex-direction: column;
    }
  }
  .desktop-background {
    &-shape {
      position: absolute;
      height: 100%;
      width: 50%;
      top: 0;
      left: 0;
      z-index: -1;
      @include xs {
        opacity: 0;
      }
    }
  }
  .mobile-background {
    &-shape {
      position: absolute;
      height: 60%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      opacity: 0;
      @include xs {
        opacity: 1;
      }
    }
  }
  .background-color {
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    z-index: -2;
    background-color: #fffce4;
  }
  .background-container {
    opacity: 0.25;
    height: 100%;
    width: 55%;
    position: absolute;
    right: 0;
    z-index: -2;
    overflow: hidden;
    background-color: transparent;
    @include xs {
      height: 50%;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0px;
      z-index: -2;
      overflow: hidden;
      background-color: transparent;
    }
    .sun {
      height: 100%;
      left: 50%;
      position: absolute;
      bottom: 0px;
      transform: translate(-50%, 0%);
      width: auto;
      z-index: 0;
      @include xs {
        height: 100%;
        left: 50%;
        position: absolute;
        bottom: 0px;
        transform: translate();
        width: auto;
      }
    }
    .sunrays {
      width: auto;
      position: absolute;
      bottom: -63%;
      left: 54%;
      height: 100%;
      z-index: -1;
    }
  }
  .rotate {
    animation: rotation 30s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: translate(-55%, -44%) rotate(0deg);
    }
    to {
      transform: translate(-55%, -44%) rotate(360deg);
    }
  }
}
